import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MqttPreset } from '../models/MqttPreset';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MqttPresetsDataService {
  private mqttSubjects: { [key: string]: BehaviorSubject<MqttPreset> } = {};

  public setPresets(uid: string, message: MqttPreset) {
    this.mqttSubjects[uid].next(message);
  }

  public getPresets(uid: string) {
    return this.mqttSubjects[uid]?.asObservable();
  }

  createPreset(uid: string) {
    this.mqttSubjects[uid] = new BehaviorSubject<MqttPreset>({} as MqttPreset);
  }

  private errorSource = new BehaviorSubject<HttpErrorResponse>(
    new HttpErrorResponse({ error: null, status: 0, statusText: '' }),
  );
  public error = this.errorSource.asObservable();

  public updateError(error: HttpErrorResponse) {
    this.errorSource.next(error);
  }
}
