import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MqttPresetsDataService } from './mqtt-presets-data.service';
import { MqttPreset, MqttPresetSignal } from '../models/MqttPreset';

@Injectable({
  providedIn: 'root',
})
export class MqttPresetsService {
  private apiUrl = environment.mqttPresetsApi;

  constructor(
    private http: HttpClient,
    private mqttPresetsDataService: MqttPresetsDataService,
  ) {}

  updatePresetsFromDb(identifier: string) {
    this.mqttPresetsDataService.createPreset(identifier);

    this.http.get<any>(`${this.apiUrl}/read/${identifier}`).subscribe({
      next: this.handleSuccess.bind(this, identifier),
      error: this.handleError.bind(this),
      complete: this.handleComplete.bind(this),
    });
  }

  private handleSuccess(uid: string, preset: MqttPreset) {
    this.mqttPresetsDataService.setPresets(uid, preset);
  }

  private handleError(error: HttpErrorResponse) {
    this.mqttPresetsDataService.updateError(error);
  }

  private handleComplete() {}

  refresh(identifier: string) {
    this.http.get<any>(`${this.apiUrl}/refresh/${identifier}`).subscribe(
      (data) => {
        // console.log(data);
      },
      (error) => {
        // console.log(error);
      },
      () => {
        // console.log('complete');
      },
    );
  }

  archive(id: string, start: Date, end: Date) {
    const payload = {
      mqtt: id,
      start: start,
      end: end,
    };

    return this.http.post<MqttPresetSignal[]>(
      `${this.apiUrl}/archive`,
      payload,
    );
  }
}
